import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import classNames from 'classnames';
import { resolveBlogCategorySlug } from '../../utils';

function Breadcrumbs({ currentPage, categoryName, categorySlug }) {
  return (
    <div className="breadcrumbs d-block d-lg-flex mb-5">
      <Link to="/blog/">Blog</Link>
      <span className="mx-2">/</span>
      <Link to={categorySlug && resolveBlogCategorySlug(categorySlug)}>
        {categoryName}
      </Link>
      {currentPage && (
        <>
          <span className="mx-2">/</span>
          <i>{currentPage}</i>
        </>
      )}
    </div>
  );
}

Breadcrumbs.propTypes = {
  currentPage: PropTypes.string,
  categoryName: PropTypes.string,
  categorySlug: PropTypes.string,
};

export default Breadcrumbs;
