import { StaticImage } from 'gatsby-plugin-image';
import { openPopupWidget, PopupModal } from 'react-calendly';
import React, { useEffect, useRef, useState } from 'react';
import jQuery from 'jquery';

const $ = jQuery;

function FollowBanner({ blogPostContentRef }) {
  const followRef = useRef(null);
  const [offsetFromTop, setOffsetTop] = useState(0);
  const [followBannerTop, setFollowBannerTop] = useState(0);

  const [isOpen, setIsOpen] = useState(false);
  const [rootElement, setRootElement] = useState(null);

  useEffect(() => {
    setRootElement(document.getElementById('___gatsby'));
  }, []);

  const handleScheduleClick = () => {
    setIsOpen(true);
  };

  useEffect(() => {
    const blogPostContentTop = blogPostContentRef.current.offsetTop - 150;
    const blogPostHeight = blogPostContentRef.current.offsetHeight + 400;

    if (followRef.current) {
      if (offsetFromTop > blogPostContentTop && offsetFromTop <= blogPostHeight) {
        setFollowBannerTop(offsetFromTop - blogPostContentTop);
      } else if (offsetFromTop >= blogPostHeight) {
        setFollowBannerTop(blogPostContentRef.current.offsetHeight - 500);
      } else {
        setFollowBannerTop(0);
      }
    }
  }, [followRef, offsetFromTop]);

  const handleScroll = () => {
    setOffsetTop(window.scrollY);
  };

  useEffect(() => {
    handleScroll();
    document.addEventListener('scroll', handleScroll, { passive: true });
    return () => {
      // clean up the event handler when the component unmounts
      document.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div
      id="sidebar-follow-banner"
      className="blog-banner-skyscraper"
      onClick={handleScheduleClick}
      ref={followRef}
      style={{ top: followBannerTop }}>
      <StaticImage
        alt="I offer confidential 30 min & 1 hour consultations"
        src="../../assets/img/blog/follow-banner.jpg"
        className="follow-image"
      />
      {rootElement && (
        <PopupModal
          url="https://calendly.com/pandevlaw"
          onModalClose={() => setIsOpen(false)}
          open={isOpen}
          rootElement={rootElement}
        />
      )}
    </div>
  );
}

export default FollowBanner;
