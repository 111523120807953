import React from 'react';

import {
  EmailShareButton,
  FacebookShareButton,
  FacebookMessengerShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  EmailIcon,
  FacebookIcon,
  FacebookMessengerIcon,
  LinkedinIcon,
  XIcon,
} from 'react-share';

import { graphql, useStaticQuery } from 'gatsby';

function ShareIcons({ pathname }) {
  const { site } = useStaticQuery(graphql`
    query SocialIconsUrlQuery {
      site {
        siteMetadata {
          url
        }
      }
    }
  `);
  const url = site.siteMetadata.url + pathname;

  const iconProps = {
    size: 32,
    round: true,
  };

  return (
    <div className="row justify-content-end blog-social-icons">
      <FacebookShareButton url={url} className="mr-2">
        <FacebookIcon {...iconProps} />
      </FacebookShareButton>
      <FacebookMessengerShareButton url={url} className="mr-2">
        <FacebookMessengerIcon {...iconProps} />
      </FacebookMessengerShareButton>
      <TwitterShareButton url={url} className="mr-2">
        <XIcon {...iconProps} />
      </TwitterShareButton>
      <LinkedinShareButton url={url} className="mr-2">
        <LinkedinIcon {...iconProps} />
      </LinkedinShareButton>
      <EmailShareButton url={url} className="mr-2">
        <EmailIcon {...iconProps} />
      </EmailShareButton>
    </div>
  );
}

ShareIcons.propTypes = {};

export default ShareIcons;
