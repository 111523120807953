import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Link } from 'gatsby';
import pandevImg from '../../assets/img/pandev-photo.jpg';

function ConversionDeck({ className = null }) {
  return (
    <div
      className={classnames('row my-5', {
        [className]: Boolean(className),
      })}>
      <div className="col-lg-6 d-flex justify-content-center flex-column">
        <h4 className="text-mega text-dark mb-5">
          I offer confidential 30 minute & 1 hour consultations.
        </h4>
        <Link
          to="/contact/"
          className="btn btn-primary mb-5"
          style={{ width: 'fit-content' }}>
          Schedule a consultation
        </Link>
      </div>
      <div className="col-lg-6">
        <img
          className="img-fluid mt-5"
          src={pandevImg}
          alt="Adrian Pandev immigration lawyer USA"
        />
      </div>
    </div>
  );
}

ConversionDeck.propTypes = {
  className: PropTypes.string,
};

export default ConversionDeck;
